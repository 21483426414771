import { Box, GridItem, SimpleGrid, VStack } from "@chakra-ui/react";
import Navbar from "../components/navbar/Navbar";
import WaBlast from "../components/ui/whatsapp-qr";
import Footer from "../components/footer";
import AvatarMenu from "../components/navbar/avatar-menu";
import HomeSidebarContent from "../components/home/home-sidebar-content";
import NavbarLinks from "../components/navbar/NavbarLinks";
import NavbarLoginButtons from "../components/navbar/login-buttons";
import useAuthentication from "../useAuthentication";
import { useEffect, useState } from "react";

function BlastPesan() {
  const { isLoggedIn, isLoading } = useAuthentication();
  const [showNavbarContent, setShowNavbarContent] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowNavbarContent(true);
    }, 200);

    return () => clearTimeout(timer);
  }, []);
  return (
    <Box>
      <Box flexGrow={1}>
        <Navbar
          sidebarContent={<HomeSidebarContent />}
          links={<NavbarLinks />}
          buttons={
            showNavbarContent &&
            (isLoggedIn ? <AvatarMenu /> : <NavbarLoginButtons />)
          }
        />
        <VStack>
          <WaBlast />
        </VStack>
      </Box>
      <Footer />
    </Box>
  );
}

export default BlastPesan;
