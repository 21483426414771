import {
  Box,
  Text,
  Flex,
  Avatar,
  Button,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuDivider,
  useDisclosure,
} from "@chakra-ui/react";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { useState, useEffect } from "react";

const AvatarMenu = () => {
  const { t } = useTranslation();
  const { i18n } = useTranslation();
  const fullname =
    localStorage.getItem("firstname") + " " + localStorage.getItem("lastname");
  const email = localStorage.getItem("email");
  const { isOpen, onOpen, onClose } = useDisclosure();
  const to_route = useNavigate();
  const location = useLocation();
  const navigate = (route) => {
    to_route(route);
  };

  const handleLogout = (e) => {
    e.preventDefault();

    axios
      .get("https://api.pawarta.awandigital.id/api/logout")
      .then((response) => {
        localStorage.clear();
        navigate("/home");
      })
      .catch((e) => {
        // ...
      });
  };

  const [currentLanguage, setCurrentLanguage] = useState("id");

  const changeLanguage = (language) => {
    setCurrentLanguage(language);
    i18n.changeLanguage(language);
  };

  useEffect(() => {
    if (location.pathname === "/dashboard" || location.pathname === "/dbblast" || location.pathname === "/dbsetting" || location.pathname === "/dbdashboard") {
      setMenuText("home");
      setNavigation("/home");
    } else {
      setMenuText("dashboard");
      setNavigation("/dashboard");
    }
  }, [location.pathname]);

  const [menuText, setMenuText] = useState("Dashboard");
  const [navigation, setNavigation] = useState("/dashboard");

  return (
    <Box px={4}>
      <Flex h={16} alignItems={"center"} justifyContent={"space-between"}>
        <Flex alignItems={"center"}>
          <Menu>
            <MenuButton
              as={Button}
              rounded={"full"}
              variant={"link"}
              cursor={"pointer"}
              minW={0}
            >
              <Avatar
                size={"sm"}
                src={
                  "https://images.unsplash.com/photo-1534308143481-c55f00be8bd7?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8NTd8fHByb2ZpbGV8ZW58MHx8MHx8fDA%3D&auto=format&fit=crop&w=500&q=60"
                }
              />
            </MenuButton>
            <MenuList>
              <Box mt={4} textAlign="center">
                <Text fontWeight="bold">{fullname}</Text>
                <Text fontSize="sm" color={"gray"}>
                  {email}
                </Text>
              </Box>
              <MenuDivider />
              <MenuItem onClick={() => navigate(navigation)}>
                {t("menuList." + menuText)}
              </MenuItem>
              <MenuItem
                onClick={() => changeLanguage("en")}
                style={{ display: currentLanguage === "en" ? "none" : "block" }}
              >
                {t("menuList.english")}
              </MenuItem>
              <MenuItem
                onClick={() => changeLanguage("id")}
                style={{ display: currentLanguage === "id" ? "none" : "block" }}
              >
                {t("menuList.indonesia")}
              </MenuItem>
              <MenuItem onClick={handleLogout}>{t("menuList.logout")}</MenuItem>
            </MenuList>
          </Menu>
        </Flex>
      </Flex>
    </Box>
  );
};

export default AvatarMenu;
