import {
  Box, TableContainer, Thead, Table, Tr, Th, Td, Tbody, IconButton, Heading,
  Flex, AlertDialog, AlertDialogOverlay, AlertDialogContent, AlertDialogHeader,
  AlertDialogBody, AlertDialogFooter, useDisclosure, useToast, Button,
  Spinner, Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter,
  ModalBody, ModalCloseButton, Text, VStack, HStack, Divider
} from "@chakra-ui/react";
import { AddIcon, DeleteIcon, EditIcon, ViewIcon } from "@chakra-ui/icons";
import AvatarMenu from "../components/navbar/avatar-menu";
import SidebarContent from "../components/dashboard/sidebar-content";
import SearchInput from "../components/search";
import axios from "axios";
import { useContext, useEffect, useState, useRef } from "react";
import Navbar from "../components/navbar/Navbar";
import EditItemDrawer from "../components/dashboard/edit-drawer";
import EditLicenseDrawer from "../components/dashboard/edit-license-drawer";
import CreateItemDrawer from "../components/dashboard/create-drawer";
import SearchContext from "../SearchContext";
import { useTranslation } from "react-i18next";
import { useNavigate } from 'react-router-dom';
import { showToast } from "../components/toast-alert";
import { Line } from 'react-chartjs-2';
import 'chart.js/auto';

function Dashboard() {
  const { t } = useTranslation();
  const toast = useToast();
  const { searchResults, setSearchResults } = useContext(SearchContext);
  const [data, setData] = useState([]);
  const [header, setHeader] = useState([]);
  const [type, setType] = useState("");
  const [loading, setLoading] = useState(true);
  const [selectedItem, setSelectedItem] = useState(null);
  const [deletingItemId, setDeletingItemId] = useState(null);
  const lastType = localStorage.getItem("lastType");
  const userRole = localStorage.getItem("role");
  const userId = localStorage.getItem("id");
  const navigate = useNavigate();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef = useRef();

  const {
    isOpen: isModalOpen,
    onOpen: onModalOpen,
    onClose: onModalClose
  } = useDisclosure();

  useEffect(() => {
    const storedId = localStorage.getItem("id");
    if (!storedId) {
      navigate('/login');
    } else {
      const storedType = localStorage.getItem("lastType");
      handleData(storedType || "Users");
    }
  }, []);

  const setLastType = (type) => {
    localStorage.setItem("lastType", type);
  };

  const handleData = (type) => {
    setLoading(true);
    let endpoint;
    let headers;

    if (type === "Users") {
      endpoint = `https://api.pawarta.awandigital.id/api/users`;
      headers = ["id", "firstname", "lastname", "telephone", "email", "license"];
    } else if (type === "Contacts") {
      endpoint = `https://api.pawarta.awandigital.id/api/contacts?user_id=${userId}`;
      headers = ["id", "name", "phone", "email"];
    } else if (type === "Groups") {
      endpoint = `https://api.pawarta.awandigital.id/api/groups?user_id=${userId}`;
      headers = ["id", "group_name"];
    } else if (type === "Reports") {
      endpoint = `https://api.pawarta.awandigital.id/api/reports?user_id=${userId}`;
      headers = ["id", "message", "telephone", "name", "status", "timestamp"];
    } else if (type === "EReports") {
      endpoint = `https://api.pawarta.awandigital.id/api/email-reports?user_id=${userId}`;
      headers = ["id", "message", "email", "name", "status", "timestamp"];
    } else if (type === "Blast") {
      navigate('/dbblast');
      return;
    } else if (type === "Gmail") {
      navigate('/dbgblast');
      return;
    } else if (type === "Setting") {
      navigate('/dbsetting');
      return;
    } else if (type === "Dashboard") {
      navigate('/dbdashboard');
      return;
    }

    axios.get(endpoint).then((response) => {
      const fetchedData = response.data.data;
      if (type === "Users" && userRole === "user") {
        setData(fetchedData.filter(user => user.id === parseInt(userId)));
      } else {
        setData(fetchedData);
      }
      setHeader(["No", ...headers]);
      setType(type.toLowerCase());
      setLoading(false);
      setLastType(type);
    }).catch((error) => {
      console.error("Error fetching data:", error);
      setLoading(false);
    });
  };

  const handleUpdateItem = (itemId, updatedItem) => {
    const endpoint = `https://api.pawarta.awandigital.id/api/${type}/${itemId}`;

    axios.put(endpoint, updatedItem)
      .then((response) => {
        showToast(toast, `${type.charAt(0).toUpperCase() + type.slice(1)} updated successfully!`, "success", "Success");
        setData(prevData =>
          prevData.map(item => (item.id === itemId ? response.data.data : item))
        );
        handleData(lastType);
      })
      .catch((error) => {
        console.error("Error updating item:", error);
      });
  };

  const handleDelete = (id) => {
    setDeletingItemId(id);
    onOpen();
  };

  const handleConfirmDelete = () => {
    const endpoint = `https://api.pawarta.awandigital.id/api/${type}/${deletingItemId}`;

    axios.delete(endpoint)
      .then(() => {
        showToast(toast, `${type.charAt(0).toUpperCase() + type.slice(1)} deleted successfully!`, "success", "Success");

        if (type === "groups") {
          const contactGroupEndpoint = `https://api.pawarta.awandigital.id/api/groups/${deletingItemId}/contacts`;

          axios.delete(contactGroupEndpoint)
            .then(() => {
              console.log("Data in contact_group table deleted successfully");
            })
            .catch((error) => {
              console.error("Error deleting data in contact_group table:", error);
            });
        }

        setData(prevData => prevData.filter(item => item.id !== deletingItemId));
        handleData(lastType);
        onClose();
      })
      .catch((error) => {
        console.error("Error deleting item:", error);
      });
  };

  const handleViewItem = (item) => {
    setSelectedItem(item);
    onModalOpen();
  };

  const truncateText = (text, maxLength = 50) => {
    if (text.length > maxLength) {
      return text.substring(0, maxLength) + "...";
    }
    return text;
  };

  return (
    <>
      <Navbar
        sidebarContent={<SidebarContent handleData={handleData} />}
        buttons={
          <>
            <SearchInput type={type} />
            <AvatarMenu />
          </>
        }
      />
      <Box as="section" minH="100vh">
        <Box ml={{ base: 0, md: 60 }} transition=".3s ease">
          <Box as="main" p={4}>
            <Box borderWidth="4px" borderStyle="dashed" rounded="md" h="auto">
              <Box h="full" w="full" overflowX="auto">
                {loading ? (
                  <Spinner size="lg" />
                ) : (
                  <TableContainer p={10}>
                    <Flex align="center" justify="space-between" pb={5}>
                      <Heading fontSize={{ base: "xl", md: "2xl" }} pb="5" textTransform="uppercase">
                        {t(type)}
                      </Heading>
                      {(type === "contacts" || type === "groups") && (
                        <CreateItemDrawer
                          dataType={type}
                          onCreate={(createdItem) => {
                            setTimeout(() => {
                              handleData(lastType, createdItem);
                            }, 2000);
                          }}
                        />
                      )}
                    </Flex>
                    <Table variant="striped" size={{ base: "sm", md: "md" }}>
                      <Thead>
                        <Tr>
                          {header.map((title) => (
                            title === "id" ? null : <Th key={title}>{title === "No" ? title : t(`${title}`)}</Th>
                          ))}
                          <Th style={{ width: '100px' }}>operations</Th>
                        </Tr>
                      </Thead>
                      <Tbody>
                        {(searchResults.length > 0 ? searchResults : data).map((item, index) => (
                          <Tr key={item.id}>
                            <Td>{index + 1}</Td> {/* Menambahkan kolom nomor urut */}
                            {header.slice(1).map((column) => (
                              column === "id" ? null : <Td key={column}>
                                {column === "message" ? truncateText(item[column]) : item[column]}
                              </Td>
                            ))}
                            <Td>
                              {(type !== "users" && type !== "reports" && type !== "ereports") && (
                                <EditItemDrawer
                                  dataType={type}
                                  item={item}
                                  onUpdate={(updatedItem) => handleUpdateItem(item.id, updatedItem)}
                                />
                              )}
                              {userRole === "superadmin" && type === "users" && (
                                <EditLicenseDrawer
                                  item={item}
                                  onUpdate={(updatedItem) => handleUpdateItem(item.id, updatedItem)}
                                />
                              )}
                              {(userRole === "superadmin" ||
                                (userRole === "user" && (type === "contacts" || type === "groups"))) && type !== "reports" && type !== "ereports" && (
                                  <IconButton
                                    onClick={() => handleDelete(item.id)}
                                    bg=""
                                    _hover={{ bg: "red.500", color: "white" }}
                                    ml={1}
                                    aria-label="Delete"
                                    icon={<DeleteIcon />}
                                  />
                                )}
                              {(type === "reports" || type === "ereports" || (type === "users" && userRole === "user")) && (
                                <IconButton
                                  onClick={() => handleViewItem(item)}
                                  bg=""
                                  _hover={{ bg: "blue.500", color: "white" }}
                                  ml={1}
                                  aria-label="View"
                                  icon={<ViewIcon />}
                                />
                              )}
                            </Td>
                          </Tr>
                        ))}
                      </Tbody>
                    </Table>
                  </TableContainer>
                )}
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>

      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Confirm Deletion
            </AlertDialogHeader>
            <AlertDialogBody>
              Are you sure? You can't undo this action afterwards.
            </AlertDialogBody>
            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onClose}>
                Cancel
              </Button>
              <Button colorScheme="red" onClick={handleConfirmDelete} ml={3}>
                Delete
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>

      <Modal isOpen={isModalOpen} onClose={onModalClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Details</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {selectedItem && (
              <VStack align="start" spacing={3}>
                {header.map((column) => (
                  <HStack key={column} w="full" justify="space-between">
                    <Text fontWeight="bold">{column === "id" ? "ID" : column}:</Text>
                    <Text>{selectedItem[column]}</Text>
                  </HStack>
                ))}
              </VStack>
            )}
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" onClick={onModalClose}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}

export default Dashboard;
