import {
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  Button,
  Stack,
  Box,
  FormLabel,
  Input,
  useDisclosure,
  useToast,
  Checkbox,
  InputGroup,
  InputRightElement,
  IconButton,
} from "@chakra-ui/react";
import { AddIcon, SearchIcon } from "@chakra-ui/icons";
import { useRef, useState, useEffect } from "react";
import axios from "axios";
import Papa from "papaparse"; // Add this line to import PapaParse for CSV parsing
import { showToast } from "../toast-alert";

function CreateItemDrawer({ dataType, onCreate }) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const firstField = useRef();
  const toast = useToast();
  const [formData, setFormData] = useState({});
  const [contacts, setContacts] = useState([]);
  const [selectedContacts, setSelectedContacts] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [contactsPerPage] = useState(10);
  const [csvData, setCsvData] = useState(null); // State for storing CSV data
  const userId = localStorage.getItem("id");

  useEffect(() => {
    if (dataType === "groups") {
      axios.get(`https://api.pawarta.awandigital.id/api/contacts?user_id=${userId}`).then((response) => {
        setContacts(response.data.data);
      });
    }
  }, [dataType]);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.id]: e.target.value });
  };

  const handleContactChange = (contactId) => {
    setSelectedContacts((prevSelected) =>
      prevSelected.includes(contactId)
        ? prevSelected.filter((id) => id !== contactId)
        : [...prevSelected, contactId]
    );
  };

  const handleSubmit = () => {
    const formDataWithUserId = { ...formData, user_id: userId };
    if (!formDataWithUserId.user_id) {
      showToast(toast, "User ID is missing!", "error", "Error");
      return;
    }

    if (csvData) {
      // Handle CSV upload for groups
      const endpoint = `https://api.pawarta.awandigital.id/api/${dataType}/bulk`;
      axios.post(endpoint, { data: csvData, user_id: userId })
        .then((response) => {
          console.log(response.data);
          showToast(toast, `${dataType} created successfully!`, "success", "Success");
          onCreate(response.data);
          onClose();
        })
        .catch((error) => {
          console.error(error);
        });
    } else {
      // Handle form data upload
      if (dataType === "groups") {
        axios.post("https://api.pawarta.awandigital.id/api/groups", formDataWithUserId)
          .then((response) => {
            const groupId = response.data.data.id;
            axios.put(`https://api.pawarta.awandigital.id/api/groups/${groupId}/contacts`, { contact_id: selectedContacts })
              .then((response) => {
                showToast(toast, "Group created successfully!", "success", "Success");
                onCreate(response.data);
                onClose();
              })
              .catch((error) => {
                console.error(error);
              });
          })
          .catch((error) => {
            console.error(error);
          });
      } else if (dataType === "contacts") {
        axios.post(`https://api.pawarta.awandigital.id/api/contacts`, formDataWithUserId)
          .then((response) => {
            showToast(toast, `${dataType} created successfully!`, "success", "Success");
            onCreate(response.data);
            onClose();
          })
          .catch((error) => {
            console.error(error);
          });
      } else {
        axios.post(`https://api.pawarta.awandigital.id/api/${dataType}`, formData)
          .then((response) => {
            showToast(toast, `${dataType} created successfully!`, "success", "Success");
            onCreate(response.data);
            onClose();
          })
          .catch((error) => {
            console.error(error);
          });
      }
    }
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
    setCurrentPage(1);
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleFileUpload = (e) => {
    const file = e.target.files[0];
    Papa.parse(file, {
      header: true,
      skipEmptyLines: true,
      complete: (results) => {
        if (dataType === "contacts") {
          const parsedData = results.data.map((row) => ({
            name: row.name,
            phone: row.phone,
            email: row.email,
          }));
          setCsvData(parsedData);
        } else if (dataType === "groups") {
          const parsedData = results.data.map((row) => ({
            group_name: row.group_name,
          }));
          setCsvData(parsedData);
        }
      },
    });
  };

  const filteredContacts = contacts.filter((contact) =>
    contact.name.toLowerCase().includes(searchTerm.toLowerCase())
  );
  const indexOfLastContact = currentPage * contactsPerPage;
  const indexOfFirstContact = indexOfLastContact - contactsPerPage;
  const currentContacts = filteredContacts.slice(indexOfFirstContact, indexOfLastContact);

  const renderInputFields = () => {
    return (
      <>
        {dataType === "contacts" && (
          <>
            <Box>
              <FormLabel htmlFor="name">Nama</FormLabel>
              <Input id="name" onChange={handleChange} />
            </Box>

            <Box>
              <FormLabel htmlFor="phone">Phone</FormLabel>
              <Input id="phone" onChange={handleChange} />
            </Box>

            <Box>
              <FormLabel htmlFor="email">Email</FormLabel>
              <Input id="email" onChange={handleChange} />
            </Box>

            <Box>
              <FormLabel htmlFor="csvUpload">Upload CSV</FormLabel>
              <Input type="file" mb="5" minH="50px" paddingTop="10px" paddingBottom="10px" id="csvUpload" accept=".csv" onChange={handleFileUpload} />
            </Box>
          </>
        )}
        {dataType === "groups" && (
          <>
            <Box>
              <FormLabel htmlFor="group_name">Nama Group</FormLabel>
              <Input id="group_name" onChange={handleChange} />
            </Box>

            <Box>
              <FormLabel htmlFor="contacts">Contacts</FormLabel>
              <InputGroup mb={3}>
                <Input
                  placeholder="Search Contacts"
                  value={searchTerm}
                  onChange={handleSearchChange}
                />
                <InputRightElement>
                  <IconButton
                    aria-label="Search"
                    icon={<SearchIcon />}
                    onClick={() => { }}
                  />
                </InputRightElement>
              </InputGroup>
              <Stack>
                {currentContacts.map((contact) => (
                  <Checkbox
                    key={contact.id}
                    isChecked={selectedContacts.includes(contact.id)}
                    onChange={() => handleContactChange(contact.id)}
                  >
                    {contact.name}
                  </Checkbox>
                ))}
              </Stack>
              <Stack direction="row" mt={3}>
                {[...Array(Math.ceil(filteredContacts.length / contactsPerPage)).keys()].map((num) => (
                  <Button key={num} onClick={() => handlePageChange(num + 1)}>
                    {num + 1}
                  </Button>
                ))}
              </Stack>
              <Box>
                <FormLabel htmlFor="csvUpload">Upload CSV</FormLabel>
                <Input type="file" mb="5" minH="50px" paddingTop="10px" paddingBottom="10px" id="csvUpload" accept=".csv" onChange={handleFileUpload} />
              </Box>
            </Box>
          </>
        )}
      </>
    );
  };

  return (
    <>
      <Button
        colorScheme="green"
        ml={4}
        leftIcon={<AddIcon color="white" />}
        onClick={onOpen}
        backgroundColor={"green"}
      >
        New item
      </Button>

      <Drawer
        isOpen={isOpen}
        placement="right"
        initialFocusRef={firstField}
        onClose={onClose}
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />

          <DrawerHeader borderBottomWidth="1px">Add {dataType}</DrawerHeader>

          <DrawerBody>
            <Stack spacing="24px">{renderInputFields()}</Stack>
          </DrawerBody>

          <DrawerFooter borderTopWidth="1px">
            <Button variant="outline" mr={3} onClick={onClose}>
              Cancel
            </Button>
            <Button colorScheme="green" px={7} onClick={handleSubmit}>
              Create
            </Button>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </>
  );
}

export default CreateItemDrawer;
