import React, { useState } from 'react';
import {
  Box,
  Button,
  Center,
  FormControl,
  FormLabel,
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Link,
  Text,
  VStack,
  useToast
} from "@chakra-ui/react";
import { EmailIcon, LockIcon, ViewIcon, ViewOffIcon } from "@chakra-ui/icons";
import { FaYoutube, FaInstagram, FaFacebook, FaTwitter } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import useAuthentication from "../useAuthentication";
import { showToast } from "../components/toast-alert";
import { useTranslation } from "react-i18next";
import pawarta_white from "../media-assets/pawarta-white.svg";
import pawarta from "../media-assets/pawarta.svg";

const LoginPage = () => {
  const { t } = useTranslation();
  const { setLoggedIn } = useAuthentication();
  const navigate = useNavigate();
  const toast = useToast();
  const [showPassword, setShowPassword] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const handlePasswordVisibility = () => setShowPassword(!showPassword);

  const handleLogin = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post("https://api.pawarta.awandigital.id/api/login", {
        email: email,
        password: password
      });

      const {
        id,
        firstname,
        lastname,
        telephone,
        email: userEmail,
        role,
        license,
        access,
        wa_message_limit,
        email_message_limit
      } = response.data.data;

      showToast(toast, "You've logged in successfully.", "success", "Success");

      localStorage.setItem("id", id);
      localStorage.setItem("firstname", firstname);
      localStorage.setItem("lastname", lastname);
      localStorage.setItem("telephone", telephone);
      localStorage.setItem("email", userEmail);
      localStorage.setItem("role", role);
      localStorage.setItem("license", license);
      localStorage.setItem("access", access);
      localStorage.setItem("wa_message_limit", wa_message_limit);
      localStorage.setItem("email_message_limit", email_message_limit);

      setLoggedIn(true);
      
      if (role === "superadmin") {
        navigate("/dbdashboard");
      } else {
        navigate("/dashboard");
      }
      
    } catch (error) {
      console.error(error);
      let message = "Login failed. Please try again.";
      if (error.response) {
        if (error.response.status === 403) {
          message = "Akun belum diverifikasi. Silakan verifikasi email Anda.";
        } else if (error.response.status === 401) {
          message = "Password salah";
        } else if (error.response.status === 404) {
          message = "Email tidak ditemukan";
        }
      }
      showToast(toast, message, "error", "Error");
    }
  };

  return (
    <Box display="flex" minH="100vh" fontFamily="Outfit">
      <Box bg="var(--green-gradient)" w={["0", "0", "40%"]} color="white" p={10} display={["none", "none", "block"]}>
        <Box>
          <img src={pawarta_white} alt="Pawarta" width="150" height="auto" />
        </Box>
        <Box mt={10}>
          <Text fontSize="2xl" fontWeight="bold">Experience Pelanggan Yang Lebih Baik</Text>
          <Text mt={4}>Berikan pengalaman terbaik kepada pelanggan dengan berbagai solusi Awan Digital</Text>
        </Box>
        <Box mt="auto">
          <Text mt={10}>Ikuti Kami:</Text>
          <Box display="flex" mt={2}>
            <Icon as={FaYoutube} w={6} h={6} mr={4} />
            <Icon as={FaInstagram} w={6} h={6} mr={4} />
            <Icon as={FaFacebook} w={6} h={6} mr={4} />
            <Icon as={FaTwitter} w={6} h={6} />
          </Box>
        </Box>
      </Box>
      <Box flex="1" display="flex" justifyContent="center" alignItems="center" p={10}>
        <Box w="full" maxW="md">
          <form onSubmit={handleLogin}>
            <img src={pawarta} alt="Pawarta" width="150" height="auto" />
            <Box mb={8} textAlign="center">
              <Text fontSize="4xl" fontWeight="bold" color="#0F1628">Login</Text>
              <Text mt={2} color="gray.500">Selamat datang kembali di Pawarta</Text>
            </Box>
            <FormControl id="email" mb={4}>
              <FormLabel>Email</FormLabel>
              <InputGroup>
                <InputLeftElement pointerEvents="none" children={<EmailIcon color="green.500" />} />
                <Input
                  type="email"
                  placeholder="Masukkan Email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  focusBorderColor="green.500"
                />
              </InputGroup>
            </FormControl>
            <FormControl id="password" mb={6}>
              <FormLabel>Password</FormLabel>
              <InputGroup>
                <InputLeftElement pointerEvents="none" children={<LockIcon color="green.500" />} />
                <Input
                  type={showPassword ? "text" : "password"}
                  placeholder="Masukkan Password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  focusBorderColor="green.500"
                />
                <InputRightElement onClick={handlePasswordVisibility} cursor="pointer">
                  {showPassword ? <ViewOffIcon color="green.500" /> : <ViewIcon color="green.500" />}
                </InputRightElement>
              </InputGroup>
            </FormControl>
            <Box textAlign="right" mb={6}>
              <Link href="/forgot-password" color="green.500" fontWeight="bold">Lupa Password?</Link>
            </Box>
            <Button
              type="submit"
              w="full"
              bg="green.500"
              color="white"
              _hover={{ bg: "green.600" }}
              _active={{ bg: "green.700" }}
              h={12}
              rounded="lg"
            >
              Login
            </Button>
            <Box textAlign="center" mt={4}>
              <Text>
                Belum punya akun? <Link href="/signup" color="green.500" fontWeight="bold">Daftar Sekarang</Link>
              </Text>
            </Box>
          </form>
        </Box>
      </Box>
    </Box>
  );
};

export default LoginPage;