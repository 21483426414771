import React, { useState, useEffect, useContext } from "react";
import {
  Box,
  useToast,
  Text,
  Heading,
  Flex,
  Spinner,
  SimpleGrid,
  Icon,
  Button,
} from "@chakra-ui/react";
import { FaUsers, FaUserShield, FaUserCheck, FaUserTimes } from "react-icons/fa";
import AvatarMenu from "../components/navbar/avatar-menu";
import SidebarContent from "../components/dashboard/sidebar-content";
import { Pie, Bar } from "react-chartjs-2"; // Using Pie from react-chartjs-2
import SearchContext from "../SearchContext";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import Navbar from "../components/navbar/Navbar";
import axios from "axios";

function Dbdashboard() {
  const { t } = useTranslation();
  const toast = useToast();
  const { searchResults, setSearchResults } = useContext(SearchContext);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const setLastType = (type) => {
    localStorage.setItem("lastType", type);
  };

  const [userStats, setUserStats] = useState({
    totalUsers: 0,
    trialUsers: 0,
    premiumUsers: 0,
    unverifiedUsers: 0,
  });

  const [dailyVerificationData, setDailyVerificationData] = useState({
    labels: [],
    datasets: [
      {
        label: "Unverified",
        data: [],
        borderColor: "rgba(255,99,132,1)",
        backgroundColor: "rgba(255,99,132,0.2)",
        fill: true,
      },
      {
        label: "Verified",
        data: [],
        borderColor: "rgba(75,192,192,1)",
        backgroundColor: "rgba(75,192,192,0.2)",
        fill: true,
      },
    ],
  });

  const [dailyLicenseData, setDailyLicenseData] = useState({
    labels: [],
    datasets: [
      {
        label: "Trial",
        data: [],
        backgroundColor: "rgba(255,206,86,0.2)",
        borderColor: "rgba(255,206,86,1)",
        fill: true,
      },
      {
        label: "Premium",
        data: [],
        backgroundColor: "rgba(54,162,235,0.2)",
        borderColor: "rgba(54,162,235,1)",
        fill: true,
      },
    ],
  });

  const [showTrial, setShowTrial] = useState(true);
  const [showPremium, setShowPremium] = useState(true);

  useEffect(() => {
    const storedId = localStorage.getItem("id");
    const userRole = localStorage.getItem("role");
    const storeId = localStorage.getItem("storeId");

    console.log("storedId:", storedId);
    if (!storedId) {
      navigate("/login");
    } else if (storeId && userRole == "user") {
      navigate("/dashboard");
    } else {
      setLastType("Dashboard");
      fetchData();
      fetchUserStats();
      fetchDailyData();
    }
  }, [navigate]);

  const fetchData = () => {
    console.log("Fetching data...");
    setTimeout(() => {
      console.log("Data fetched, setting loading to false");
      setLoading(false);
    }, 2000);
  };

  const fetchUserStats = async () => {
    try {
      const response = await axios.get("https://api.pawarta.awandigital.id/api/user-stats");
      setUserStats(response.data);
    } catch (error) {
      console.error("Error fetching user stats:", error);
    }
  };

  const fetchDailyData = async () => {
    try {
      const response = await axios.get("https://api.pawarta.awandigital.id/api/daily-verification-data");
      const { labels, unverified, verified } = response.data;

      setDailyVerificationData({
        labels: labels,
        datasets: [
          {
            label: "Unverified",
            data: unverified,
            borderColor: "rgba(255,99,132,1)",
            backgroundColor: "rgba(255,99,132,0.2)",
            fill: true,
          },
          {
            label: "Verified",
            data: verified,
            borderColor: "rgba(75,192,192,1)",
            backgroundColor: "rgba(75,192,192,0.2)",
            fill: true,
          },
        ],
      });
    } catch (error) {
      console.error("Error fetching daily verification data:", error);
    }

    try {
      const response = await axios.get("https://api.pawarta.awandigital.id/api/daily-license-data");
      const { labels, trial, premium } = response.data;

      setDailyLicenseData({
        labels: labels,
        datasets: [
          {
            label: "Trial",
            data: trial,
            backgroundColor: "rgba(255,206,86,0.2)",
            borderColor: "rgba(255,206,86,1)",
            fill: true,
          },
          {
            label: "Premium",
            data: premium,
            backgroundColor: "rgba(54,162,235,0.2)",
            borderColor: "rgba(54,162,235,1)",
            fill: true,
          },
        ],
      });
    } catch (error) {
      console.error("Error fetching daily license data:", error);
    }
  };

  const handleData = (type) => {
    console.log("Handling data for type:", type);
    setLoading(true);
    if (type === "Users") {
      setLastType("Users");
    } else if (type === "Contacts") {
      setLastType("Contacts");
    } else if (type === "Groups") {
      setLastType("Groups");
    } else if (type === "Reports") {
      setLastType("Reports");
    } else if (type === "EReports") {
      setLastType("EReports");
    } else if (type === "Blast") {
      setLastType("Blast");
      navigate("/dbblast");
    } else if (type === "Gmail") {
      setLastType("Gmail");
      navigate("/dbgblast");
    } else if (type === "Setting") {
      setLastType("Setting");
      navigate("/dbsetting");
    }
    setLoading(false);
  };

  return (
    <>
      <Navbar
        sidebarContent={<SidebarContent handleData={handleData} />}
        buttons={
          <>
            <Heading>Pengaturan</Heading>
            <AvatarMenu />
          </>
        }
      />
      <Box ml={{ base: 0, md: 60 }} transition=".3s ease">
        <Box as="main" p={4}>
          {loading ? (
            <Flex justify="center" align="center" h="100vh">
              <Spinner size="lg" />
            </Flex>
          ) : (
            <Box>
              <Heading mb={4}>Dashboard</Heading>
              <SimpleGrid columns={{ base: 1, md: 2, lg: 4 }} spacing={4} mb={4}>
                <Box p={4} bg="teal.100" borderWidth={1} borderRadius="md" display="flex" alignItems="center">
                  <Icon as={FaUsers} w={6} h={6} mr={2} />
                  <Box>
                    <Heading size="md">Total Users</Heading>
                    <Text>{userStats.totalUsers}</Text>
                  </Box>
                </Box>
                <Box p={4} bg="yellow.100" borderWidth={1} borderRadius="md" display="flex" alignItems="center">
                  <Icon as={FaUserShield} w={6} h={6} mr={2} />
                  <Box>
                    <Heading size="md">Trial Users</Heading>
                    <Text>{userStats.trialUsers}</Text>
                  </Box>
                </Box>
                <Box p={4} bg="green.100" borderWidth={1} borderRadius="md" display="flex" alignItems="center">
                  <Icon as={FaUserCheck} w={6} h={6} mr={2} />
                  <Box>
                    <Heading size="md">Premium Users</Heading>
                    <Text>{userStats.premiumUsers}</Text>
                  </Box>
                </Box>
                <Box p={4} bg="red.100" borderWidth={1} borderRadius="md" display="flex" alignItems="center">
                  <Icon as={FaUserTimes} w={6} h={6} mr={2} />
                  <Box>
                    <Heading size="md">Unverified Users</Heading>
                    <Text>{userStats.unverifiedUsers}</Text>
                  </Box>
                </Box>
              </SimpleGrid>
              <Box>
                <SimpleGrid columns={{ base: 1, md: 2 }} spacing={4}>
                  <Box p={4} borderWidth={1} borderRadius="md" bg="white" maxW={{ base: "100%", md: "100%" }} w="100%" h="400px">
                    <Pie
                      data={{
                        labels: dailyVerificationData.labels,
                        datasets: dailyVerificationData.datasets,
                      }}
                      options={{
                        maintainAspectRatio: false,
                        plugins: {
                          legend: {
                            position: "right",
                          },
                        },
                      }}
                    />
                  </Box>
                  <Box p={4} borderWidth={1} borderRadius="md" bg="white" maxW={{ base: "100%", md: "100%" }} w="100%" h="400px">
                    <Bar data={dailyLicenseData} options={{ maintainAspectRatio: false }} />
                  </Box>
                </SimpleGrid>
              </Box>
            </Box>
          )}
        </Box>
      </Box>
    </>
  );
}

export default Dbdashboard;
