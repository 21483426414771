import {
  Box,
  Flex,
  Text,
  Drawer,
  DrawerContent,
  DrawerOverlay,
  useDisclosure,
  IconButton,
  useToast
} from "@chakra-ui/react";
import { HamburgerIcon } from "@chakra-ui/icons";
import NavItem from "./nav-item";
import { FaUserFriends } from "react-icons/fa";
import { BsGear, BsPerson, BsTelephone, BsPeople, BsReceipt, BsBarChart, BsEnvelope } from "react-icons/bs";
import { Link } from "react-router-dom";

const SidebarContent = ({ handleData, ...props }) => {
  const sidebar = useDisclosure();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();
  const userLicense = localStorage.getItem("license");
  const userRole = localStorage.getItem("role"); // Assuming role is stored in localStorage

  const handleRestrictedData = (type) => {
    if (userLicense === "trial" && (type === "Blast" || type === "Gmail")) {
      toast({
        title: "License Trial",
        description: "Perbarui License Anda Untuk Mengakses Halaman Ini.",
        status: "warning",
        duration: 5000,
        isClosable: true,
      });
    } else {
      handleData(type);
    }
  };

  return (
    <>
      <Box
        display={{
          base: "none",
          md: "unset",
        }}
        as="nav"
        pos="fixed"
        top="0"
        left="0"
        zIndex="sticky"
        h="full"
        pb="10"
        overflowX="hidden"
        overflowY="auto"
        bg="green.500"
        borderColor="blackAlpha.300"
        borderRightWidth="1px"
        w="60"
        {...props}
      >
        <Flex px="4" py="5" align="center">
          <Text fontSize="2xl" ml="2" color="white" fontWeight="semibold">
            Pawarta
          </Text>
        </Flex>
        <Flex
          direction="column"
          as="nav"
          fontSize="sm"
          color="gray.600"
          aria-label="Main Navigation"
        >
          {userRole === "superadmin" && (
            <NavItem icon={BsBarChart} onClick={() => handleRestrictedData("Dashboard")}>
              Dashboard
            </NavItem>
          )}
          <Link to="/dashboard" style={{ textDecoration: 'none' }}>
            <NavItem icon={FaUserFriends} onClick={() => handleData("Users")}>
              Users
            </NavItem>
          </Link>
          {userRole === "user" && (
          <Link to="/dashboard" style={{ textDecoration: 'none' }}>
            <NavItem icon={BsPerson} onClick={() => handleData("Contacts")}>
              Contacts
            </NavItem>
          </Link>
          )}
          {userRole === "user" && (
          <Link to="/dashboard" style={{ textDecoration: 'none' }}>
            <NavItem icon={BsPeople} onClick={() => handleData("Groups")}>
              Groups
            </NavItem>
          </Link>
          )}
          {userRole === "user" && (
          <Link to="/dashboard" style={{ textDecoration: 'none' }}>
            <NavItem icon={BsReceipt} onClick={() => handleData("Reports")}>
              Whatsapp Reports
            </NavItem>
          </Link>
          )}
          {userRole === "user" && (
          <Link to="/dashboard" style={{ textDecoration: 'none' }}>
            <NavItem icon={BsReceipt} onClick={() => handleData("EReports")}>
              Email Reports
            </NavItem>
          </Link>
          )}
          {userRole === "user" && (
          <NavItem icon={BsTelephone} onClick={() => handleRestrictedData("Blast")}>
            Whatsapp Blasts
          </NavItem>
          )}
          {userRole === "user" && (
          <NavItem icon={BsEnvelope} onClick={() => handleRestrictedData("Gmail")}>
            Email Blasts
          </NavItem>
          )}
          {userRole === "superadmin" && (
          <NavItem icon={BsGear} onClick={() => handleRestrictedData("Setting")}>
            Settings
          </NavItem>
          )}
        </Flex>
      </Box>
      <Drawer
        isOpen={sidebar.isOpen}
        onClose={sidebar.onClose}
        placement="left"
      >
        <DrawerOverlay />
        <DrawerContent bg={"red"}>
          <Box
            as="nav"
            pos="fixed"
            top="0"
            left="0"
            zIndex="sticky"
            h="full"
            pb="10"
            overflowX="hidden"
            overflowY="auto"
            bg="green.700"
            borderColor="blackAlpha.300"
            borderRightWidth="1px"
            w="full"
            {...props}
          >
            <Flex px="4" py="5" align="center">
              <Text fontSize="2xl" ml="2" color="white" fontWeight="semibold">
                Pawarta
              </Text>
            </Flex>
            <Flex
              direction="column"
              as="nav"
              fontSize="sm"
              color="gray.600"
              aria-label="Main Navigation"
            >
              {userRole === "superadmin" && (
                <NavItem icon={BsBarChart} onClick={() => handleRestrictedData("Dashboard")}>
                  Dashboard
                </NavItem>
              )}
              <NavItem icon={FaUserFriends} onClick={() => handleData("Users")}>
                Users
              </NavItem>
              <NavItem icon={BsPerson} onClick={() => handleData("Contacts")}>
                Contacts
              </NavItem>
              <NavItem icon={BsPeople} onClick={() => handleData("Groups")}>
                Groups
              </NavItem>
              <NavItem icon={BsReceipt} onClick={() => handleData("Reports")}>
                Whatsapp Reports
              </NavItem>
              <NavItem icon={BsReceipt} onClick={() => handleData("EReports")}>
                Email Reports
              </NavItem>
              <NavItem icon={BsTelephone} onClick={() => handleRestrictedData("Blast")}>
                Whatsapp Blasts
              </NavItem>
              <NavItem icon={BsEnvelope} onClick={() => handleRestrictedData("Gmail")}>
                Email Blasts
              </NavItem>
              <NavItem icon={BsGear} onClick={() => handleRestrictedData("Setting")}>
                Settings
              </NavItem>
            </Flex>
          </Box>
        </DrawerContent>
      </Drawer>

      <IconButton
        icon={<HamburgerIcon />}
        aria-label="Menu"
        display={{
          base: "inline-flex",
          md: "none",
        }}
        onClick={sidebar.onOpen}
        size="sm"
      />
    </>
  );
};

export default SidebarContent;
