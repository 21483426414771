import React, { useState } from 'react';
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  InputLeftElement,
  Link,
  Text,
  useToast
} from "@chakra-ui/react";
import { LockIcon } from "@chakra-ui/icons";
import { useSearchParams, useNavigate } from "react-router-dom";
import axios from "axios";
import { showToast } from "../components/toast-alert";
import { useTranslation } from "react-i18next";
import pawarta_white from "../media-assets/pawarta-white.svg";
import pawarta from "../media-assets/pawarta.svg";

const ResetPassword = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const toast = useToast();
  const [searchParams] = useSearchParams();
  const [password, setPassword] = useState('');
  const [passwordConfirmation, setPasswordConfirmation] = useState('');
  const token = searchParams.get('token');
  const email = decodeURIComponent(searchParams.get('email'));

  const handleResetPassword = async (e) => {
    e.preventDefault();

    try {
      await axios.post("https://api.pawarta.awandigital.id/api/reset-password", {
        email,
        token,
        password,
        password_confirmation: passwordConfirmation,
      });

      showToast(toast, "Password has been reset successfully.", "success", "Success");
      navigate("/login");
    } catch (error) {
      console.error(error);
      let message = "Failed to reset password. Please try again.";
      if (error.response) {
        if (error.response.status === 400) {
          message = "Invalid data provided.";
        } else if (error.response.status === 500) {
          message = "Server error. Please try again later.";
        }
      }
      showToast(toast, message, "error", "Error");
    }
  };

  return (
    <Box display="flex" minH="100vh" fontFamily="Outfit">
      <Box bg="var(--green-gradient)" w={["0", "0", "40%"]} color="white" p={10} display={["none", "none", "block"]}>
        <Box>
          <img src={pawarta_white} alt="Pawarta" width="150" height="auto" />
        </Box>
        <Box mt={10}>
          <Text fontSize="2xl" fontWeight="bold">Experience Pelanggan Yang Lebih Baik</Text>
          <Text mt={4}>Berikan pengalaman terbaik kepada pelanggan dengan berbagai solusi Awan Digital</Text>
        </Box>
      </Box>
      <Box flex="1" display="flex" justifyContent="center" alignItems="center" p={10}>
        <Box w="full" maxW="md">
          <form onSubmit={handleResetPassword}>
            <img src={pawarta} alt="Pawarta" width="150" height="auto" />
            <Box mb={8} textAlign="center">
              <Text fontSize="4xl" fontWeight="bold" color="#0F1628">Reset Password</Text>
              <Text mt={2} color="gray.500">Enter your new password below</Text>
            </Box>
            <FormControl id="password" mb={4}>
              <FormLabel>New Password</FormLabel>
              <InputGroup>
                <InputLeftElement pointerEvents="none" children={<LockIcon color="green.500" />} />
                <Input
                  type="password"
                  placeholder="Enter your new password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  focusBorderColor="green.500"
                />
              </InputGroup>
            </FormControl>
            <FormControl id="password_confirmation" mb={4}>
              <FormLabel>Confirm Password</FormLabel>
              <InputGroup>
                <InputLeftElement pointerEvents="none" children={<LockIcon color="green.500" />} />
                <Input
                  type="password"
                  placeholder="Re-enter your new password"
                  value={passwordConfirmation}
                  onChange={(e) => setPasswordConfirmation(e.target.value)}
                  focusBorderColor="green.500"
                />
              </InputGroup>
            </FormControl>
            <Button
              type="submit"
              w="full"
              bg="green.500"
              color="white"
              _hover={{ bg: "green.600" }}
              _active={{ bg: "green.700" }}
              h={12}
              rounded="lg"
            >
              Reset Password
            </Button>
            <Box textAlign="center" mt={4}>
              <Text>
                Remember your password? <Link href="/login" color="green.500" fontWeight="bold">Login</Link>
              </Text>
            </Box>
          </form>
        </Box>
      </Box>
    </Box>
  );
};

export default ResetPassword;
