import React, { useState } from 'react';
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  InputLeftElement,
  Link,
  Text,
  useToast
} from "@chakra-ui/react";
import { EmailIcon } from "@chakra-ui/icons";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { showToast } from "../components/toast-alert";
import { useTranslation } from "react-i18next";
import pawarta_white from "../media-assets/pawarta-white.svg";
import pawarta from "../media-assets/pawarta.svg";

const ForgotPasswordPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const toast = useToast();
  const [email, setEmail] = useState("");

  const handleForgotPassword = async (e) => {
    e.preventDefault();

    try {
      await axios.post("https://api.pawarta.awandigital.id/api/forgot-password", {
        email: email,
      });

      showToast(toast, "Password reset link has been sent to your email.", "success", "Success");
      navigate("/login");
    } catch (error) {
      console.error(error);
      let message = "Failed to send password reset link. Please try again.";
      if (error.response) {
        if (error.response.status === 404) {
          message = "Email not found.";
        }
      }
      showToast(toast, message, "error", "Error");
    }
  };

  return (
    <Box display="flex" minH="100vh" fontFamily="Outfit">
      <Box bg="var(--green-gradient)" w={["0", "0", "40%"]} color="white" p={10} display={["none", "none", "block"]}>
        <Box>
          <img src={pawarta_white} alt="Pawarta" width="150" height="auto" />
        </Box>
        <Box mt={10}>
          <Text fontSize="2xl" fontWeight="bold">Experience Pelanggan Yang Lebih Baik</Text>
          <Text mt={4}>Berikan pengalaman terbaik kepada pelanggan dengan berbagai solusi Awan Digital</Text>
        </Box>
      </Box>
      <Box flex="1" display="flex" justifyContent="center" alignItems="center" p={10}>
        <Box w="full" maxW="md">
          <form onSubmit={handleForgotPassword}>
            <img src={pawarta} alt="Pawarta" width="150" height="auto" />
            <Box mb={8} textAlign="center">
              <Text fontSize="4xl" fontWeight="bold" color="#0F1628">Forgot Password</Text>
              <Text mt={2} color="gray.500">Masukkan email anda untuk mengubah password anda</Text>
            </Box>
            <FormControl id="email" mb={4}>
              <FormLabel>Email</FormLabel>
              <InputGroup>
                <InputLeftElement pointerEvents="none" children={<EmailIcon color="green.500" />} />
                <Input
                  type="email"
                  placeholder="Enter your email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  focusBorderColor="green.500"
                />
              </InputGroup>
            </FormControl>
            <Button
              type="submit"
              w="full"
              bg="green.500"
              color="white"
              _hover={{ bg: "green.600" }}
              _active={{ bg: "green.700" }}
              h={12}
              rounded="lg"
            >
              Send Reset Link
            </Button>
            <Box textAlign="center" mt={4}>
              <Text>
                Remember your password? <Link href="/login" color="green.500" fontWeight="bold">Login</Link>
              </Text>
            </Box>
          </form>
        </Box>
      </Box>
    </Box>
  );
};

export default ForgotPasswordPage;
