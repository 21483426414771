import { useState } from 'react';
import { Button, Heading, Text, Box, Input, Textarea, useToast } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { FaWhatsapp } from "react-icons/fa";
import QRCode from 'qrcode.react';

const WaBlast = () => {
  const { t } = useTranslation();
  const [phoneNumber, setPhoneNumber] = useState('');
  const [message, setMessage] = useState('');
  const [showQR, setShowQR] = useState(false);
  const toast = useToast();

  const formatPhoneNumber = (number) => {
    // Remove leading zeros
    const formattedNumber = number.replace(/^0+/, '');
    // Check if number starts with +62
    if (formattedNumber.startsWith('+62')) {
      return formattedNumber;
    } else {
      // Add prefix +62
      return `+62${formattedNumber}`;
    }
  };

  const generateQRCode = () => {
    const formattedPhoneNumber = formatPhoneNumber(phoneNumber);
    // Check if message has been sent today
    const lastSent = localStorage.getItem('lastSent');
    if (lastSent && new Date() - new Date(lastSent) < 24 * 60 * 60 * 1000) {
      toast({
        title: "Pesan tidak dapat dikirim",
        description: "Anda hanya dapat mengirim pesan sekali dalam sehari menggunakan nomer anda, untuk versi lebih lengkapnya anda bisa daftar dulu",
        status: "error",
        duration: 7000,
        isClosable: true,
      });
    } else {
      // Logic to generate WhatsApp link
      const whatsappLink = `https://wa.me/${formattedPhoneNumber}?text=${encodeURIComponent(message)}`;
      console.log(whatsappLink);
      // Show QR code
      setShowQR(true);
      // Update last sent time
      localStorage.setItem('lastSent', new Date().toISOString());
    }
  };

  const handleInputChange = (e) => {
    // Reset showQR state when input changes
    setShowQR(false);
    // Update corresponding state
    if (e.target.name === 'phoneNumber') {
      // Only allow numbers in phone number input
      const regex = /^[0-9\b]+$/;
      if (e.target.value === '' || regex.test(e.target.value)) {
        setPhoneNumber(e.target.value);
      }
    } else if (e.target.name === 'message') {
      setMessage(e.target.value);
    }
  };

  return (
    <Box p={4}>
      <Heading as="h1" size="lg" mb={4}>
        {t("waBlast.title")}
      </Heading>
      <Text mb={4}>{t("waBlast.description")}</Text>
      <Input
        mb={4}
        placeholder={t("waBlast.phoneNumberPlaceholder")}
        value={phoneNumber}
        name="phoneNumber"
        onChange={handleInputChange}
      />
      <Textarea
        mb={4}
        placeholder={t("waBlast.messagePlaceholder")}
        value={message}
        name="message"
        onChange={handleInputChange}
      />
      <Button
        leftIcon={<FaWhatsapp />}
        colorScheme="green"
        onClick={generateQRCode}
        isDisabled={!phoneNumber || !message}
      >
        {t("waBlast.button")}
      </Button>
      {showQR && (
        <Box mt={4}>
          <QRCode value={`https://wa.me/${formatPhoneNumber(phoneNumber)}?text=${encodeURIComponent(message)}`} />
        </Box>
      )}
    </Box>
  );
};

export default WaBlast;
