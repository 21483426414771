import React from 'react';
import {
    VStack,
    Box,
    Center,
    Link,
    Text,
    Button,
    Input,
    InputGroup,
    InputLeftElement,
    InputRightElement,
    FormControl,
    FormLabel,
    Checkbox,
    Icon
} from "@chakra-ui/react";
import { EmailIcon, LockIcon, ViewIcon, ViewOffIcon, PhoneIcon } from "@chakra-ui/icons";
import { useTranslation } from "react-i18next";
import { FaYoutube, FaInstagram, FaFacebook, FaTwitter } from "react-icons/fa";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useToast } from "@chakra-ui/react";
import useAuthentication from "../useAuthentication";
import { showToast } from "../components/toast-alert";
import pawarta_white from "../media-assets/pawarta-white.svg";
import pawarta from "../media-assets/pawarta.svg";

const SignUpPage = () => {
    const { t } = useTranslation();
    const toast = useToast();
    const navigate = useNavigate();
    const [showPassword, setShowPassword] = React.useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = React.useState(false);
    const [firstname, setFirstname] = React.useState("");
    const [lastname, setLastname] = React.useState("");
    const [telephone, setTelephone] = React.useState("");
    const [email, setEmail] = React.useState("");
    const [password, setPassword] = React.useState("");
    const [confirmPassword, setConfirmPassword] = React.useState("");
    const [termsAccepted, setTermsAccepted] = React.useState(false);
    const passwordRegEx = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/;

    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0');
    const day = String(today.getDate()).padStart(2, '0');

    const formattedDate = `${year}-${month}-${day}`;

    const handlePasswordVisibility = () => setShowPassword(!showPassword);
    const handleConfirmPasswordVisibility = () => setShowConfirmPassword(!showConfirmPassword);

    const createUserAccount = async (e) => {
        e.preventDefault();

        if (!password.match(passwordRegEx)) {
            return showToast(toast, "Password must be minimum 8 characters, at least 1 letter and 1 number.", "error", "Error");
        }

        if (password !== confirmPassword) {
            return showToast(toast, "Passwords do not match.", "error", "Error");
        }

        if (!termsAccepted) {
            return showToast(toast, "You must accept the terms and conditions.", "error", "Error");
        }

        try {
            const response = await axios.post("https://api.pawarta.awandigital.id/api/signup", {
                firstname,
                lastname,
                telephone,
                email,
                password,
                role: "user",
                license: "trial",
                license_period: formattedDate,
                wa_message_limit: 0,
                email_message_limit: 0
            });

            await sendVerificationEmail(email);

            showToast(toast, "Registration successful! Please check your email for verification link.", "success", "Success");
            navigate("/login");
        } catch (error) {
            const errorMessage = error.response && error.response.data && error.response.data.message
                ? error.response.data.message
                : "An error occurred. Please try again.";
            showToast(toast, errorMessage, "error", "Error");
        }            
    };

    const sendVerificationEmail = async (email) => {
        try {
            await axios.post("https://api.pawarta.awandigital.id/api/send-verification-email", { email });
        } catch (error) {
            throw error;
        }
    };

    return (
        <Box display="flex" minH="100vh" fontFamily="Outfit">
            <Box
                bg="var(--green-gradient)"
                w={["0", "0", "40%"]}
                color="white"
                p={10}
                display={["none", "none", "block"]}
                position="fixed"
                h="100vh"
                overflowY="auto"
            >
                <Box>
                    <img src={pawarta_white} alt="Pawarta" width="150" height="auto" />
                </Box>
                <Box mt={10}>
                    <Text fontSize="2xl" fontWeight="bold">Pengeluaran Untuk Solusi Teknologi yang Lebih Cerdas</Text>
                    <Text mt={4}>Fokus kepada bisnis inti dan hemat biaya pengembangan aplikasi berulang melalui solusi cloud dan bundling platform Awan Digital</Text>
                </Box>
                <Box mt="auto">
                    <Text mt={10}>Ikuti Kami:</Text>
                    <Box display="flex" mt={2}>
                        <Icon as={FaYoutube} w={6} h={6} mr={4} />
                        <Icon as={FaInstagram} w={6} h={6} mr={4} />
                        <Icon as={FaFacebook} w={6} h={6} mr={4} />
                        <Icon as={FaTwitter} w={6} h={6} />
                    </Box>
                </Box>
            </Box>
            <Box flex="1" display="flex" justifyContent="center" alignItems="center" p={10} ml={["0", "0", "40%"]}>
                <Box w="full" maxW="md">
                    <form onSubmit={createUserAccount}>
                        <img src={pawarta} alt="Pawarta" width="150" height="auto" />
                        <Box mb={8} textAlign="center">
                            <Text fontSize="4xl" fontWeight="bold" color="#0F1628">Sign Up</Text>
                            <Text mt={2} color="gray.500">Create a new account</Text>
                        </Box>
                        <FormControl id="firstname" mb={4}>
                            <FormLabel>First Name</FormLabel>
                            <Input
                                type="text"
                                placeholder="Enter First Name"
                                value={firstname}
                                onChange={(e) => setFirstname(e.target.value)}
                                focusBorderColor="green.500"
                            />
                        </FormControl>
                        <FormControl id="lastname" mb={4}>
                            <FormLabel>Last Name</FormLabel>
                            <Input
                                type="text"
                                placeholder="Enter Last Name"
                                value={lastname}
                                onChange={(e) => setLastname(e.target.value)}
                                focusBorderColor="green.500"
                            />
                        </FormControl>
                        <FormControl id="telephone" mb={4}>
                            <FormLabel>Telephone</FormLabel>
                            <InputGroup>
                                <InputLeftElement pointerEvents="none" children={<PhoneIcon color="green.500" />} />
                                <Input
                                    type="tel"
                                    placeholder="Enter Telephone"
                                    value={telephone}
                                    onChange={(e) => setTelephone(e.target.value)}
                                    focusBorderColor="green.500"
                                />
                            </InputGroup>
                        </FormControl>
                        <FormControl id="email" mb={4}>
                            <FormLabel>Email</FormLabel>
                            <InputGroup>
                                <InputLeftElement pointerEvents="none" children={<EmailIcon color="green.500" />} />
                                <Input
                                    type="email"
                                    placeholder="Enter Email"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    focusBorderColor="green.500"
                                />
                            </InputGroup>
                        </FormControl>
                        <FormControl id="password" mb={4}>
                            <FormLabel>Password</FormLabel>
                            <InputGroup>
                                <InputLeftElement pointerEvents="none" children={<LockIcon color="green.500" />} />
                                <Input
                                    type={showPassword ? "text" : "password"}
                                    placeholder="Enter Password"
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                    focusBorderColor="green.500"
                                />
                                <InputRightElement onClick={handlePasswordVisibility} cursor="pointer">
                                    {showPassword ? <ViewOffIcon color="green.500" /> : <ViewIcon color="green.500" />}
                                </InputRightElement>
                            </InputGroup>
                        </FormControl>
                        <FormControl id="confirmPassword" mb={4}>
                            <FormLabel>Confirm Password</FormLabel>
                            <InputGroup>
                                <InputLeftElement pointerEvents="none" children={<LockIcon color="green.500" />} />
                                <Input
                                    type={showConfirmPassword ? "text" : "password"}
                                    placeholder="Confirm Password"
                                    value={confirmPassword}
                                    onChange={(e) => setConfirmPassword(e.target.value)}
                                    focusBorderColor="green.500"
                                />
                                <InputRightElement onClick={handleConfirmPasswordVisibility} cursor="pointer">
                                    {showConfirmPassword ? <ViewOffIcon color="green.500" /> : <ViewIcon color="green.500" />}
                                </InputRightElement>
                            </InputGroup>
                        </FormControl>
                        <FormControl id="terms" mb={6}>
                            <Checkbox
                                isChecked={termsAccepted}
                                onChange={(e) => setTermsAccepted(e.target.checked)}
                                colorScheme="green"
                            >
                                Saya telah membaca dan menyetujui <Link href="#" color="green.500" textDecoration="underline">syarat dan ketentuan</Link> yang berlaku.
                            </Checkbox>
                        </FormControl>
                        <Button
                            type="submit"
                            w="full"
                            bg="green.500"
                            color="white"
                            _hover={{ bg: "green.600" }}
                            _active={{ bg: "green.700" }}
                            h={12}
                            rounded="lg"
                        >
                            Register
                        </Button>
                        <Box textAlign="center" mt={4}>
                            <Text>
                                Already have an account? <Link href="/login" color="green.500" fontWeight="bold">Log In</Link>
                            </Text>
                        </Box>
                    </form>
                </Box>
            </Box>
        </Box>
    );
};

export default SignUpPage;