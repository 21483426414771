import React, { useState, useEffect, useContext, useRef } from "react";
import {
    Box,
    Heading,
    Flex,
    useToast,
    Button,
    Textarea,
    Input,
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    Checkbox,
    Spinner,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    IconButton,
} from "@chakra-ui/react";
import AvatarMenu from "../components/navbar/avatar-menu";
import SidebarContent from "../components/dashboard/sidebar-content";
import axios from "axios";
import { useTranslation } from "react-i18next";
import Navbar from "../components/navbar/Navbar";
import { showToast } from "../components/toast-alert";
import { useNavigate } from 'react-router-dom';
import { ViewIcon } from "@chakra-ui/icons";

function DashboardBlast() {
    const { t } = useTranslation();
    const toast = useToast();
    const [loading, setLoading] = useState(false);
    const [qrGenerated, setQrGenerated] = useState(false);
    const [qrPath, setQrPath] = useState("");
    const [contacts, setContacts] = useState([]);
    const [groups, setGroups] = useState([]);
    const [selectedGroups, setSelectedGroups] = useState([]);
    const [selectedContacts, setSelectedContacts] = useState([]);
    const [selectedGroup, setSelectedGroup] = useState(null);
    const [message, setMessage] = useState("");
    const [media, setMedia] = useState("");
    const [filename, setFilename] = useState("");
    const [view, setView] = useState("selection");
    const [showGroupModal, setShowGroupModal] = useState(false);
    const [groupUsers, setGroupUsers] = useState([]);
    const MAX_MEDIA_SIZE = 10 * 1024 * 1024;
    const setLastType = (type) => {
        localStorage.setItem("lastType", type);
    };
    const navigate = useNavigate();
    const userId = localStorage.getItem("id");
    const toastShownRef = useRef(false);

    useEffect(() => {
        const storedId = localStorage.getItem("id");
        const userLicense = localStorage.getItem("license");

        if (!storedId) {
            navigate('/login');
        } else if (userLicense === "trial") {
            if (!toastShownRef.current) {
                toast({
                    title: "License Trial",
                    description: "Perbarui License Anda Untuk Mengakses Halaman Ini.",
                    status: "warning",
                    duration: 5000,
                    isClosable: true,
                });
                toastShownRef.current = true;
                navigate('/dashboard');
            }
        } else {
            setLastType("Blast");
            fetchContacts();
            fetchGroups();
        }
    }, [navigate, toast]);

    useEffect(() => {
        setSelectedContacts([]);
        setSelectedGroups([]);
    }, [view]);

    const handleData = (type) => {
        setLoading(true);
        if (type === "Users") {
            setLoading(false);
            setLastType("Users")
        } else if (type === "Contacts") {
            setLoading(false);
            setLastType("Contacts")
        } else if (type === "Groups") {
            setLoading(false);
            setLastType("Groups")
        } else if (type === "Reports") {
            setLoading(false);
            setLastType("Reports")
        } else if (type === "EReports") {
            setLoading(false);
            setLastType("EReports")
        } else if (type === "Gmail") {
            setLoading(false);
            setLastType("Gmail")
            navigate('/dbgblast');
        } else if (type === "Setting") {
            setLoading(false);
            setLastType("Setting")
            navigate('/dbsetting');
        } else if (type === "Dashboard") {
            setLoading(false);
            setLastType("Dashboard")
            navigate('/dbdashboard');
        }
    };

    const fetchContacts = async () => {
        try {
            setLoading(true);
            const response = await axios.get(`https://api.pawarta.awandigital.id/api/contacts?user_id=${userId}`);
            if (response.data && Array.isArray(response.data.data)) {
                setContacts(response.data.data);
            } else {
                toast({
                    title: "Error",
                    description: "Failed to fetch contacts",
                    status: "error",
                    duration: 3000,
                    isClosable: true,
                });
            }
            setLoading(false);
        } catch (error) {
            console.error("Failed to fetch contacts:", error);
            setLoading(false);
            toast({
                title: "Error",
                description: "Failed to fetch contacts",
                status: "error",
                duration: 9000,
                isClosable: true,
            });
        }
    };

    const fetchGroups = async () => {
        try {
            setLoading(true);
            const response = await axios.get(`https://api.pawarta.awandigital.id/api/groups?user_id=${userId}`);
            if (response.data && Array.isArray(response.data.data)) {
                const groupsWithContacts = response.data.data.map(group => ({
                    ...group,
                    contacts: group.contacts || []
                }));
                setGroups(groupsWithContacts);
            } else {
                toast({
                    title: "Error",
                    description: "Failed to fetch groups: unexpected data format",
                    status: "error",
                    duration: 9000,
                    isClosable: true,
                });
            }
            setLoading(false);
        } catch (error) {
            console.error("Failed to fetch groups:", error);
            setLoading(false);
            toast({
                title: "Error",
                description: "Failed to fetch groups",
                status: "error",
                duration: 9000,
                isClosable: true,
            });
        }
    };

    const handleSelectAll = () => {
        if (selectedContacts.length === contacts.length) {
            setSelectedContacts([]);
        } else {
            setSelectedContacts(contacts.map(contact => contact.id));
        }
    };

    const handleSelectAllGroups = async () => {
        if (selectedGroups.length === groups.length) {
            setSelectedGroups([]);
            setSelectedContacts([]);
        } else {
            const newSelectedGroups = groups.map(group => group.id);
            setSelectedGroups(newSelectedGroups);

            const newSelectedContacts = [];
            for (const groupId of newSelectedGroups) {
                const groupContacts = await fetchGroupContacts(groupId);
                newSelectedContacts.push(...groupContacts.map(contact => contact.id));
            }
            setSelectedContacts(newSelectedContacts);
        }
    };

    const handleSelectGroup = async (groupId) => {
        setSelectedGroups(prevSelected => {
            const isSelected = prevSelected.includes(groupId);
            const newSelectedGroups = isSelected
                ? prevSelected.filter(id => id !== groupId)
                : [...prevSelected, groupId];

            updateSelectedContacts(newSelectedGroups);

            return newSelectedGroups;
        });
    };

    const updateSelectedContacts = async (newSelectedGroups) => {
        const newSelectedContacts = new Set();

        for (const groupId of newSelectedGroups) {
            const groupContacts = await fetchGroupContacts(groupId);
            groupContacts.forEach(contact => newSelectedContacts.add(contact.id));
        }

        setSelectedContacts([...newSelectedContacts]);
    };

    const handleSelectContact = (contactId) => {
        setSelectedContacts(prevSelected =>
            prevSelected.includes(contactId)
                ? prevSelected.filter(id => id !== contactId)
                : [...prevSelected, contactId]
        );
    };

    const generateQR = async () => {
        const sessionId = localStorage.getItem("id") || "mysession";
        if (!sessionId) {
            toast({
                title: "Error",
                description: "Session ID tidak ditemukan",
                status: "error",
                duration: 9000,
                isClosable: true,
            });
            return;
        }

        try {
            setLoading(true);
            const response = await axios.get(`http://128.199.217.52/start-session?session=${sessionId}&scan=true`);
            const qrCodeRegex = /let qr = '(.+?)'/;
            const qrCodeMatch = response.data.match(qrCodeRegex);

            if (qrCodeMatch) {
                const qrCode = qrCodeMatch[1];
                setQrPath(qrCode);
                setQrGenerated(true);
            } else {
                toast({
                    title: "Error",
                    description: "QR code not found in the response",
                    status: "error",
                    duration: 9000,
                    isClosable: true,
                });
            }

            setLoading(false);
        } catch (error) {
            console.error("Failed to generate QR code:", error);
            setLoading(false);
            toast({
                title: "Error",
                description: "Failed to generate QR code",
                status: "error",
                duration: 9000,
                isClosable: true,
            });
        }
    };

    const handleDeleteSession = async () => {
        const sessionId = localStorage.getItem("id") || "mysession";
        if (!sessionId) {
            toast({
                title: "Error",
                description: "Session ID tidak ditemukan",
                status: "error",
                duration: 9000,
                isClosable: true,
            });
            return;
        }

        try {
            setLoading(true);
            await axios.get(`http://128.199.217.52/delete-session?session=${sessionId}`);
            localStorage.removeItem("id");
            setQrGenerated(false);
            setQrPath("");
            toast({
                title: "Success",
                description: "Session deleted successfully",
                status: "success",
                duration: 9000,
                isClosable: true,
            });
            setLoading(false);
        } catch (error) {
            console.error("Failed to delete session:", error);
            setLoading(false);
            toast({
                title: "Error",
                description: "Failed to delete session",
                status: "error",
                duration: 9000,
                isClosable: true,
            });
        }
    };

    const formatPhoneNumber = (phone) => {
        return phone.startsWith('0') ? '62' + phone.slice(1) : phone;
    };

    const handleFileChange = async (event) => {
        const file = event.target.files[0];
        if (file) {
            if (file.size > MAX_MEDIA_SIZE) {
                toast({
                    title: "Error",
                    description: "File size exceeds the maximum limit of 10MB",
                    status: "error",
                    duration: 9000,
                    isClosable: true,
                });
                return;
            }

            const formData = new FormData();
            formData.append('file', file);

            try {
                const response = await axios.post('https://api.pawarta.awandigital.id/api/upload', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                });

                if (response.data && response.data.filename) {
                    const fileUrl = response.data.filename;
                    setMedia(fileUrl);
                    setFilename(file.name);
                    toast({
                        title: "Success",
                        description: "File uploaded successfully",
                        status: "success",
                        duration: 9000,
                        isClosable: true,
                    });
                } else {
                    toast({
                        title: "Error",
                        description: "Failed to upload file",
                        status: "error",
                        duration: 9000,
                        isClosable: true,
                    });
                }
            } catch (error) {
                console.error("Failed to upload file:", error);
                toast({
                    title: "Error",
                    description: "Failed to upload file",
                    status: "error",
                    duration: 9000,
                    isClosable: true,
                });
            }
        }
    };

    const sendMessagesToReports = async () => {
        const formattedContacts = contacts
            .filter(contact => selectedContacts.includes(contact.id))
            .map(contact => ({
                message,
                telephone: contact.phone,
                name: contact.name,
                status: "Sent",
                timestamp: new Date().toISOString().slice(0, 19).replace('T', ' '),
                user_id: userId
            }));

        try {
            setLoading(true);
            await axios.post("https://api.pawarta.awandigital.id/api/reports", formattedContacts);
            setLoading(false);
        } catch (error) {
            console.error("Failed to save messages to reports:", error);
            setLoading(false);
            toast({
                title: "Error",
                description: "Failed to save messages to reports",
                status: "error",
                duration: 9000,
                isClosable: true,
            });
        }
    };

    const handleSendMessages = async () => {
        if (selectedContacts.length === 0 && selectedGroups.length === 0) {
            toast({
                title: "Error",
                description: "No contacts or groups selected",
                status: "error",
                duration: 9000,
                isClosable: true,
            });
            return;
        }

        const selectedGroupContacts = selectedGroups.flatMap(groupId => {
            const group = groups.find(group => group.id === groupId);
            if (group) {
                return group.contacts.map(contact => contact.id);
            }
            return [];
        });

        const allContacts = new Set([...selectedContacts, ...selectedGroupContacts]);
        const formattedContacts = contacts
            .filter(contact => allContacts.has(contact.id))
            .map(contact => ({
                to: formatPhoneNumber(contact.phone),
                text: message,
                media,
                filename,
                message,
                telephone: contact.phone,
                name: contact.name,
                status: "Sent",
                user_id: userId
            }));

        try {
            setLoading(true);
            const response = await axios.post("http://128.199.217.52/send-bulk-message", {
                session: localStorage.getItem("id"),
                data: formattedContacts,
                delay: 1000,
            }, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });

            if (response.data.status) {
                toast({
                    title: "Success",
                    description: "Messages sent successfully",
                    status: "success",
                    duration: 9000,
                    isClosable: true,
                });
                await sendMessagesToReports();
            } else {
                toast({
                    title: "Error",
                    description: "Failed to send messages",
                    status: "error",
                    duration: 9000,
                    isClosable: true,
                });
            }
        } catch (error) {
            console.error("Failed to send messages:", error);
            toast({
                title: "Error",
                description: `Failed to send messages: ${error.message}`,
                status: "error",
                duration: 9000,
                isClosable: true,
            });
        } finally {
            setLoading(false);
        }
    };

    const fetchGroupUsers = async (groupId) => {
        try {
            const response = await axios.get(`https://api.pawarta.awandigital.id/api/group-contacts/${groupId}/contacts`);
            if (response.data && Array.isArray(response.data.data)) {
                setGroupUsers(response.data.data);
            } else {
                toast({
                    title: "Error",
                    description: "Failed to fetch group users: unexpected data format",
                    status: "error",
                    duration: 9000,
                    isClosable: true,
                });
            }
        } catch (error) {
            console.error("Failed to fetch group users:", error);
            setLoading(false);
            toast({
                title: "Error",
                description: "Failed to fetch group users",
                status: "error",
                duration: 9000,
                isClosable: true,
            });
        }
    };

    const fetchGroupContacts = async (groupId) => {
        if (!groupId) {
            toast({
                title: "Error",
                description: "Group ID is not defined",
                status: "error",
                duration: 9000,
                isClosable: true,
            });
            return [];
        }
        try {
            const response = await axios.get(`https://api.pawarta.awandigital.id/api/groups/${groupId}/contacts`);
            if (response.data && Array.isArray(response.data.data)) {
                return response.data.data;
            } else {
                toast({
                    title: "Error",
                    description: "Failed to fetch group contacts",
                    status: "error",
                    duration: 9000,
                    isClosable: true,
                });
                return [];
            }
        } catch (error) {
            console.error(`Failed to fetch contacts for group ${groupId}:`, error);
            toast({
                title: "Error",
                description: `Failed to fetch contacts for group ${groupId}`,
                status: "error",
                duration: 9000,
                isClosable: true,
            });
            return [];
        }
    };    

    const handleViewGroup = async (groupId) => {
        const group = groups.find(g => g.id === groupId);
        if (group) {
            setSelectedGroup(group);
            setShowGroupModal(true);
            
            const users = await fetchGroupContacts(groupId);
            setGroupUsers(users);
        } else {
            toast({
                title: "Error",
                description: "Group not found",
                status: "error",
                duration: 9000,
                isClosable: true,
            });
        }
    };

    const closeGroupModal = () => {
        setShowGroupModal(false);
        setSelectedGroup(null);
        setGroupUsers([]);
    };

    return (
        <>
            <Navbar
                sidebarContent={<SidebarContent handleData={handleData} />}
                buttons={
                    <>
                        <Heading>Blast</Heading>
                        <AvatarMenu />
                    </>
                }
            />

            <Box
                as="section"
                minH="100vh"
                paddingLeft="20px"
                paddingRight="20px"
                ml={{
                    base: 0,
                    md: 60,
                }}
                transition=".3s ease"
            >
                <Flex direction="row" justifyContent="space-between" alignItems="center" mb={4}>
                    <Heading as="h1" size="lg">
                        {t("Whatsapp Blast")}
                    </Heading>
                    <Button
                        colorScheme="red"
                        onClick={handleDeleteSession}
                        isLoading={loading} // Tambahkan loading state untuk feedback visual
                    >
                        Delete Session
                    </Button>
                </Flex>
                <Flex align="center" justify="center" direction="column">
                    <Box
                        borderWidth="2px"
                        borderStyle="solid"
                        borderColor="gray.300"
                        rounded="md"
                        p="2"
                        mb="4"
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                        width="fit-content"
                    >
                        {qrGenerated ? (
                            <img src={qrPath} alt="QR Code" style={{ maxWidth: "100%", height: "auto" }} />
                        ) : (
                            <Button
                                colorScheme="green"
                                onClick={generateQR}
                                isLoading={loading}
                                loadingText="Generating QR..."
                            >
                                Generate QR
                            </Button>
                        )}
                    </Box>

                    <Box borderWidth="2px" borderStyle="solid" borderColor="gray.300" rounded="md" p="4" m="4" w="full">
                        <Flex justifyContent="center" mb={4}>
                            <Button mr={2} onClick={() => setView("contacts")}>
                                {t("Show Contacts")}
                            </Button>
                            <Button onClick={() => setView("groups")}>
                                {t("Show Groups")}
                            </Button>
                        </Flex>

                        {view === "contacts" && (
                            <>
                                <Heading as="h2" size="md" mb={4}>
                                    {t("Kontak")}
                                </Heading>
                                <Table variant="striped">
                                    <Thead>
                                        <Tr>
                                            <Th>
                                                <Checkbox
                                                    isChecked={selectedContacts.length === contacts.length}
                                                    onChange={handleSelectAll}
                                                />
                                            </Th>
                                            <Th>{t("Name")}</Th>
                                            <Th>{t("Phone")}</Th>
                                        </Tr>
                                    </Thead>
                                    <Tbody>
                                        {contacts.map(contact => (
                                            <Tr key={contact.id}>
                                                <Td>
                                                    <Checkbox
                                                        isChecked={selectedContacts.includes(contact.id)}
                                                        onChange={() => handleSelectContact(contact.id)}
                                                    />
                                                </Td>
                                                <Td>{contact.name}</Td>
                                                <Td>{contact.phone}</Td> {/* Ubah ini menjadi email */}
                                            </Tr>
                                        ))}
                                    </Tbody>
                                </Table>
                            </>
                        )}

                        {view === "groups" && (
                            <>
                                <Heading as="h2" size="md" mb={4}>
                                    {t("Groups")}
                                </Heading>
                                <Table variant="striped">
                                    <Thead>
                                        <Tr>
                                            <Th>
                                                <Checkbox
                                                    isChecked={selectedGroups.length === groups.length}
                                                    onChange={handleSelectAllGroups}
                                                />
                                            </Th>
                                            <Th>{t("Name")}</Th>
                                            <Th>{t("View")}</Th>
                                        </Tr>
                                    </Thead>
                                    <Tbody>
                                        {groups.map(group => (
                                            <Tr key={group.id}>
                                                <Td>
                                                    <Checkbox
                                                        isChecked={selectedGroups.includes(group.id)}
                                                        onChange={() => handleSelectGroup(group.id)}
                                                    />
                                                </Td>
                                                <Td>{group.group_name}</Td>
                                                <Td>
                                                    <IconButton onClick={() => handleViewGroup(group.id)} icon={<ViewIcon />} />
                                                </Td>
                                            </Tr>
                                        ))}
                                    </Tbody>
                                </Table>
                            </>
                        )}
                    </Box>

                    <Box borderWidth="2px" borderStyle="solid" borderColor="gray.300" rounded="md" p="4" w="full">
                        <Heading as="h2" mb="4">
                            {t("waDBBlast.sendMessage")}
                        </Heading>
                        <Textarea
                            placeholder="Enter your message"
                            mb="3"
                            value={message}
                            onChange={(e) => setMessage(e.target.value)}
                        />
                        <Input type="file" mb="5" minH="50px" paddingTop="10px" paddingBottom="10px" onChange={handleFileChange} />
                        <Button colorScheme="green" onClick={handleSendMessages}>Send</Button>
                    </Box>
                    <Modal isOpen={showGroupModal} onClose={closeGroupModal}>
                        <ModalOverlay />
                        <ModalContent>
                            <ModalHeader>{selectedGroup?.group_name}</ModalHeader>
                            <ModalCloseButton />
                            <ModalBody>
                                <Table variant="simple" mb={4}>
                                    <Thead>
                                        <Tr>
                                            <Th>{t("name")}</Th>
                                            <Th>{t("phone")}</Th>
                                        </Tr>
                                    </Thead>
                                    <Tbody>
                                        {groupUsers.map((user) => (
                                            <Tr key={user.id}>
                                                <Td>{user.name}</Td>
                                                <Td>{user.phone}</Td>
                                            </Tr>
                                        ))}
                                    </Tbody>
                                </Table>
                            </ModalBody>
                            <ModalFooter>
                                <Button colorScheme="red" mr={3} onClick={closeGroupModal}>
                                    {t("close")}
                                </Button>
                            </ModalFooter>
                        </ModalContent>
                    </Modal>
                </Flex>
            </Box>
        </>
    );
}

export default DashboardBlast;